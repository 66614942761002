html {
  font-size: 14px;
}

// Color system

$blue: #007bff !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #dc3545 !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$green: #28a745 !default;
$teal: #20c997 !default;
$cyan: #17a2b8 !default;
$white: #ffffff !default;
$gray: #6c757d !default;
$gray-dark: #343a40 !default;

$primary: #2a9fd6 !default;
$secondary: #6c757d !default;
$success: #1bc98e !default;
$info: #9f86ff !default;
$warning: #e4d836 !default;
$danger: #e64759 !default;
$light: #f8f9fa !default;
$dark: #1a1c22 !default;

// Body

$body-bg: #ffffff !default;

// Fonts

$font-size-base: 0.9rem !default;

$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-bold: 700 !default;

$font-weight-base: $font-weight-light !default;
$line-height-base: 1.5 !default;

// Grid system

$grid-gutter-width: 1rem !default;

// Navbar

$navbar-padding-y: 0.5rem;
$navbar-padding-x: 0.8rem;

@import "~bootstrap-scss/bootstrap";

.btn-xs {
  padding: 0.1rem 0.2rem;
  font-size: 0.75rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-pill {
  padding-left: 0.95em;
  padding-right: 0.95em;
  border-radius: 1000em;
}

.btn-square {
  border-radius: 0;
}
